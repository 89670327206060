import React from 'react'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP } from './HeroElements'
import Video from '../videos/post-it-notes-video.mp4'

const Hero = () => {
  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <HeroH1>Fray Harvey Marketing & Communications</HeroH1>
        <HeroP>Helping businesses tell their stories and articulate value with compelling and insightful content that builds brands, supports client relationships and creates a platform for revenue generation.</HeroP>
      </HeroContent>
    </HeroContainer>

  )
}

export default Hero
