import React from 'react'

import Sidebar from '../SideBar/Sidebar'
import Navbar from '../Navbar/Navbar'
import Hero from '../heroSection/Hero'


import WhoWeAre from './WhoWeAre'
import WhatWeDo from './WhatWeDo'
import Contact  from './Contact'

const Homepage = () => {

  const [isOpen, setIsOpen] = React.useState(false)
  
  const onToggle = () => {
    setIsOpen(!isOpen)
  } 

  return (
    <>
      <Sidebar isOpen={isOpen} onToggle={onToggle} />
      <Navbar onToggle={onToggle} />
      <Hero />
      <WhoWeAre />
      <WhatWeDo />
      <Contact />
    </>
  )
}

export default Homepage
