import React from 'react'

function WhatWeDo() {
  return (
    <section className="what" id='what-we-do'>
      <div className='box-one-what'>
        <div className='image-one'></div>
        <h3>Case study writing</h3>
        <div className='text-one'>
        Case studies are simply the best way to show your audience how you can help them.  We can work with you and your clients to produce case studies that showcase your capabilities and your clients’ strengths. 
        </div>
      </div>
      <div className='box-two-what'>
        <div className='image-two'></div>
        <h3>Marketing collateral</h3>
        <div className='text-two'>
        Clear, concise and informative collateral highlights your products and services in the most relevant way to your audience.  We can work with you to create customer-centric collateral showing how you can solve your clients’ challenges. 
        </div>
      </div>
      <div className='box-three-what'>
        <div className='image-three'></div>
        <h3>Content writing</h3>
        <div className='text-three'>
        Articles, white papers, blogs and reports all help position you as a market leader who understands your customers’ challenges and their opportunities.  We can work with your SMEs and add our expertise and research to create insightful content that leads the conversation. 
        </div>
      </div>
    </section>

  )
}

export default WhatWeDo
