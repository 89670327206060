import React from 'react'
import { FaBars } from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks } from './NavbarElements'


const Navbar = ({ onToggle }) => {

  const toggleHome = () => {
    scroll.scrollToTop()
  }

  return (

    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to='/' onClick={toggleHome}>FH</NavLogo>
          <MobileIcon onClick={onToggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks  
                to='who-we-are' 
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
              >Who We Are</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks 
                to='what-we-do'
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
              >What We Do</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks 
                to='contact'
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
              >Contact</NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar
