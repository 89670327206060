import React from 'react'

import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink } from './SidebarElements'

const Sidebar = ({ isOpen, onToggle }) => {

  console.log('open', isOpen)

  return (

    <SidebarContainer isOpen={isOpen} onClick={onToggle}>
      <Icon onClick={onToggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='who-we-are' onClick={onToggle}>
            Who We Are
          </SidebarLink>
          <SidebarLink to='what-we-do' onClick={onToggle}>
            What We Do
          </SidebarLink>
          <SidebarLink to='contact' onClick={onToggle}>
            Contact
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>

  )

}

export default Sidebar