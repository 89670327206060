import React from 'react'

function Contact() {
  return (
    <section className="contact" id='contact'>
      <div className="left-side-contact">
        <h1>Get in touch</h1>
        <div className="underline-contact">
        </div>
        <div className="contact-box">
          <div className='email-box'>
            <div className='email-icon'></div><a href="mailto:contact@frayharvey.com">contact@frayharvey.com</a>
          </div>
          <div className='phone-box'>
            <div className='phone-icon'></div><a href="tel:+447799234497">+44 (0)7799 234 497</a>
          </div>
        </div>
      </div>
      <div className="right-side-contact">

 
      </div>
    </section>

  )
}

export default Contact
