

function WhoWeAre() {

  return (
    <section className="who" id='who-we-are'>
      <div className="left-side-who">

      </div>
      <div className="right-side-who">
        <h1>Who we are</h1>
        <div className="underline">

        </div>
        <div className="text-box-who">
        We understand you need authoritative, clear and impactful content to underpin your business.  Leveraging over two decades of marketing and communications experience across financial, professional and technology services, we can work with you in a partnership that is both responsive and proactive.  Our commitment is to amplify your message, tell your story and communicate with your audience with content to fit your business strategy. 
        </div>
      </div>
    </section>

  )


}

export default WhoWeAre